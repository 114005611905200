import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import Table from "../../components/table/table";
import WebClockState from "../../slices/web-clock/webClockState";
import { webClockSelector } from "../../slices/web-clock/webClockSlice";
import { PaylocityRecordTypes, PunchModel, PunchTypes } from "../../app/data/web-clock/models";
import { getRecordLabel } from "../../app/data/web-clock/helpers";

import "./additionalDetails.scss";
import "./../../styles/blocks.scss";

interface AdditionalDetailsProps {
  onCancel: () => void;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = (props) => {  
  const webClockState: WebClockState = useSelector(webClockSelector);  

  const columns = [
    {
      width: 268,
      Header: "Event Time",
      accessor: "date",
      Cell: (props: any) => (
      <>
      {dayjs(props.row.original.datetime).format("MM/DD/YYYY")}
      <span className="xgs-table__cell-subitem">{dayjs(props.row.original.datetime).format("h:mm a")}</span>
      </>)     
    },
    {
      width: 268,
      Header: "Action",
      accessor: (row: {type: PaylocityRecordTypes}) => getRecordLabel(row.type),      
    },
  ];

  const logData = webClockState.punchDetails?.reduce((result: any, punch: PunchModel, index: number) => {
    let log: any[] = [];

    if (punch.punchType === PunchTypes.WORK && (index === 0 || webClockState.punchDetails?.[index - 1]?.punchType === PunchTypes.WORK)) {
      log = [...log, { datetime: punch.relativeStart, type: PaylocityRecordTypes.IN }];
    } 
    
    if (punch.punchType === PunchTypes.WORK && !!punch.relativeEnd && webClockState.punchDetails?.[index + 1]?.punchType !== PunchTypes.LUNCH) {
      log = [...log, { datetime: punch.relativeEnd, type: PaylocityRecordTypes.OUT }];
    } 
    
    if (punch.punchType === PunchTypes.LUNCH) {
      log = [
        ...log, 
        { datetime: punch.relativeStart, type: PaylocityRecordTypes.START_LUNCH },
        ...punch.relativeEnd ? [{ datetime: punch.relativeEnd, type: PaylocityRecordTypes.END_LUNCH }] : [],
      ];
    } 
    
    return [...result, ...log];
  }, []) || [];

  return (
    <div className="xgs-additional-details">
      <div className="xgs-additional-details__heading">
        Activity log:
      </div>
      <Table
        columns={columns}
        data={logData}
      />
    </div>
  );
}

export default AdditionalDetails;
