import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import dateFormats from "../app/data/common/dateFormats";

dayjs.extend(utc);
dayjs.extend(timezone);

export {};

declare global {
  interface Date {
    toUiFormat: () => string;
    toApiFormat: () => string;
  }
}

export function initDateExtensions() {
  // eslint-disable-next-line no-extend-native
  Date.prototype.toUiFormat = function() {
    return dayjs(this).format(dateFormats.uiDate);
  };
  // eslint-disable-next-line no-extend-native
  Date.prototype.toApiFormat = function () {
    return dayjs(this).format(dateFormats.apiDate);
  };
}
