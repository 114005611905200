import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import LogRocket from "logrocket";
import { useAppDispatch } from "../hooks/storeHooks";
import DataLoader from "../features/data-loader/dataLoader";
import PageLoading from "../features/page-loading/pageLoading";
import {
  getRouteConfig,
  publicRoutesComponents,
  ROUTES
} from "./route/RoutesConfig";
import Header from "../components/header/header";
import Menu from "../components/menu/menu";
import XGSErrorMessage from "../components/error-message/errorMessage";
import initExtensions from "../extensions/initExtensions";
import {
  getCurrentUser,
  userSelector
} from "../slices/user/userSlice";
import UserState from "../slices/user/UserState";
import RouteSummaryState from "../slices/route-summary/RouteSummaryState";
import { routeSummarySelector } from "../slices/route-summary/routeSummarySlice";
import "react-toastify/dist/ReactToastify.css";
import "./app.scss";

let appInited = false;

initExtensions();

if (process.env.REACT_APP_XGS_ENV === "production") {
  LogRocket.init("isggxn/xgs-driver-portal");
  window.addEventListener("unhandledrejection", e => {
    LogRocket.captureException(e.reason);
  });
}

const App: React.FC = () => {
  const userState: UserState = useSelector(userSelector);
  const dispatch = useAppDispatch();
  const routeSummaryState: RouteSummaryState = useSelector(routeSummarySelector);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setError(false);
    if ((appInited && userState.loggedIn) || (window.location.pathname === ROUTES.authCallback)) return;
    appInited = true;
    dispatch(getCurrentUser(() => {}, (statusCode: number) => {
      if (statusCode === 401) {
        const redirectUrl = process.env.REACT_APP_DOMAIN + `/auth-callback?originUrl=${encodeURIComponent(window.location.href)}`;
        const authUrl = process.env.REACT_APP_AUTH_ENDPOINT + `?client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${redirectUrl}`;
        window.location.assign(authUrl);
      } else {
        setError(true);
      }
    }));
  }, [dispatch, userState.loggedIn]);

  return (
    <div className="xgs-site">
      <BrowserRouter>
        <DataLoader dataToLoad={{webClock: true}}/>
        <Header />
        <div className="xgs-site__wrapper">
          <Routes>
            {(publicRoutesComponents.length > 0) && publicRoutesComponents.map((route, i) =>
              <Route key={i} path={route.path} element={<route.component />} />
            )}
          </Routes>
          {!userState.loginProcess &&
            !userState.logoutProcess &&
            !userState.getCurrentUser_is_Started && (
            <>
              {userState.loggedIn && (
                <>
                  <Routes>
                    {getRouteConfig(userState.profile).map((route, i) =>
                      <Route
                        key={i}
                        path={route.path}
                        element={
                          <>
                            {(routeSummaryState.route?.manifestNumber !== null) && (route.path !== ROUTES.manifests) && (
                              <Menu />
                            )}
                            <route.component />
                          </>
                        }
                      />
                    )}
                    <Route
                      path="*"
                      element={<Navigate to={ROUTES.manifests}/>}
                    />
                  </Routes>
                </>
              )}
            </>
          )}
          <Routes>
            {(userState.logoutProcess || userState.getCurrentUser_is_Started) && (
              <Route path="*" element={<PageLoading isLoading={true} />} />
            )}
          </Routes>
          {error && (
            <XGSErrorMessage className="block-center xgs-space__top__48">
              {userState.failReason || "Error. Please try again later."}
            </XGSErrorMessage>
          )}
        </div>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default App;

