import React from "react";
import { NumericFormat } from "react-number-format";

export interface AmountProps {
  amount: number | undefined;
}

const Amount: React.FC<AmountProps> = (props) => {
  return (
    <NumericFormat
      value={props.amount}
      decimalScale={2}
      displayType={"text"}
      fixedDecimalScale={true}
      thousandSeparator={true}
      prefix={"$"}
    />
  );
}

export default Amount;