export interface TerminalLocationModel {
  terminalName: string;
  terminalCode: number;
  terminalZone: string;
}

export enum PaylocityRecordTypes {
  IN = "In",
  OUT = "Out",
  START_LUNCH = "StartLunch",
  END_LUNCH = "EndLunch",
  TRANSFER = "Transfer",
};

export interface WebClockBaseRecordModel {
  recordType: PaylocityRecordTypes,
  costCenter?: number,
}

export interface WebClockRecordModel extends WebClockBaseRecordModel {
  date: string;
  time: string;
  employeeId?: number,
}

export enum PunchTypes {
  WORK = "work",
  LUNCH = "lunch",
};

export interface PunchModel {
  punchType: PunchTypes,
  relativeStart: string,
  relativeEnd: string | null,
  costCenters?: CostCenter[],
}

export interface CostCenter {
  level: number,
  code: string,
}