import { PunchModel, PunchTypes } from "./models";

class PunchDetails {
  details: PunchModel[];
  
  constructor() {
    this.details = [];
  }

  addPunch = (relativeStart: string, punchType: PunchTypes, costCenter: string) => {
    const punch = {
      punchType,
      relativeStart,
      relativeEnd: null,
      costCenters: [{
        level: 1,
        code: costCenter,
      }],
    };

    this.details = [...this.details, punch];
  }

  closePunch = (relativeEnd: string) => {
    const index = this.details.length - 1;
    const newPunchDetails = [...this.details];
    newPunchDetails[index] = {...newPunchDetails[index], relativeEnd};

    this.details = newPunchDetails;
  }
}

export default PunchDetails