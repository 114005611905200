import React, { useEffect, useRef, useState } from "react";
import "./addressMapButton.scss";
import "./notifyConsignee.scss";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import Loading from "../../components/loading/loading";
import Button, { ButtonThemes } from "../../components/button/button";
import { toast } from "react-toastify";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import { getEligibleConsignees, notifyConsigneesAtStop, notifyConsigneeSelector, notifyShippersAtStop, resetNotifyConsgineeState } from "../../slices/notify-customer/notifyCustomerSlice";
import { useAppDispatch } from "../../hooks/storeHooks";
import { useSelector } from "react-redux";
import { Field, Form, Formik, FormikProps } from "formik";

export interface NotifyConsigneeProps {
  stopNumber?: number;
  manifestNumber?: number;
  isPickup?: boolean;
  pickupNumber?: string;
  showModal: boolean;
  onClose: any;
}

const notifyMessages = () => {
  return [
    {
      label: "I have arrived",
      value: "ARRIVED"
    },
    {
      label: "On my way",
      value: "ON_MY_WAY"
    }
  ]
}
const NotifyConsignee: React.FC<NotifyConsigneeProps> = (props) => {
  const dispatch = useAppDispatch();
  const notifyConsigneeState = useSelector(notifyConsigneeSelector);
  const [type, setType] = useState("");
  const formRef = useRef<FormikProps<{type: string}>>(null);

  useEffect(() => {
    if (props.showModal && !props.isPickup) {
      dispatch(getEligibleConsignees(props.manifestNumber!, props.stopNumber!))

      return () => {
        dispatch(resetNotifyConsgineeState());
      }
    }
  }, [dispatch, props.showModal, props.manifestNumber, props.stopNumber, props.isPickup])

  const onChangeType = (value: string) => {
    setType(value);
  }

  const clear = () => setType("");

  const notifyConsignee = async () => {
    dispatch(notifyConsigneesAtStop(
      props.manifestNumber!,
      props.stopNumber!,
      type,
      () => {
        toast.info("Consignee(s) have been notified");
        clear();
      },
      (error?: string) => {
        toast.error(error || "Something went wrong");
      }))
  }

  const notifyShippers = async () => {
    if (!props.pickupNumber) return;
    dispatch(notifyShippersAtStop(
      [props.pickupNumber],
      type,
      () => {
        toast.info("Shipper(s) have been notified");
        clear();
      },
      (error?: string) => {
        toast.error(error || "Something went wrong")
      }))
  }

  const onCloseModal = () => {
    clear();
    formRef.current?.resetForm();
    props.onClose();
  }

  let isLoadingEligibleConsignees = notifyConsigneeState.requestCreator === "ELIGIBLE_CONSIGNEES" && notifyConsigneeState.requestStarted
  let hasEligibleConsignees = !!notifyConsigneeState.eligibleConsignees.length;

  return (
    <div className="xgs-notify-consignee">
      <SlideInModal
        show={props.showModal}
        title="Notify Clients"
        onClose={onCloseModal}
      >
        {(isLoadingEligibleConsignees && !props.isPickup) && <Loading className="xgs-notify-consignee__loading" isLoading/>}
        {(!isLoadingEligibleConsignees || props.isPickup) && (
          <div>
            <div className="xgs-notify-consignee__title">Stop {props.stopNumber}</div>
            {(!hasEligibleConsignees && !props.isPickup) && notifyConsigneeState.requestSucceed && <XGSErrorMessage>Consignees do not have contact information</XGSErrorMessage>}
            {(hasEligibleConsignees || props.isPickup) && (
              <>
                {!props.isPickup && <div className="xgs-notify-consignee__info">Notification can be sent to consignee(s): {notifyConsigneeState.eligibleConsignees?.map((consignee) => consignee.name).join(", ")}</div>}
                {props.isPickup && <div className="xgs-notify-consignee__info">Select one of the following messages to notify clients at this stop:</div>}
                <div>
                  <Formik innerRef={formRef} onSubmit={() => {props.isPickup ? notifyShippers() : notifyConsignee()}} initialValues={{type}}>
                    {(formProps) => (
                      <Form>
                        {notifyMessages().map(message => (
                          <div key={message.value} className="xgs-notify-consignee__radio">
                            <Field
                              onChange={() => onChangeType(message.value)} 
                              checked={type === message.value}
                              type="radio" 
                              name="notification" 
                              id={"notification-" + message.value}
                            />
                            <label htmlFor={"notification-" + message.value}>{message.label}</label>
                          </div>
                        ))}
                        <div className="xgs-notify-consignee__submit">
                          <Button 
                            spinner={notifyConsigneeState.requestStarted} 
                            disabled={!type || (!props.isPickup && !hasEligibleConsignees)} 
                            theme={ButtonThemes.blue}
                          > Submit
                          </Button>
                          <Button theme={ButtonThemes.gray} onClick={onCloseModal} type="button">Cancel</Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            )}
          </div>
        )}
      </SlideInModal>
    </div>
  );
}

export default NotifyConsignee;
