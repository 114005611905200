import React from "react";
import XGSIcons from "../../../components/icon/xgsIcons";
import XGSIcon from "../../../components/icon/xgsIcon";
import "../stopDetails.scss";

export interface StopDetailsHeaderProps {
  manifestNumber: number;
  stopNumber: number;
  probillsCount: number;
  itemsCount: number;
  consigneesCount?: number;
  address: {
    address: string;
    additionalLine?: string;
  };
  type: string;
}

export const StopDetailsHeader: React.FC<StopDetailsHeaderProps> = (props) => {
  return (
    <>
      <div className="xgs-stop-details__header">
        <div className="xgs-stop-details__address">
          <div className="xgs-stop-details__address__value">
            {props.address.address}
          </div>
          {props.address.additionalLine && props.consigneesCount === 1 &&
            <>
              <div className="xgs-stop-details__address__label">
                Additional Address Details:
              </div>
              <div className="xgs-stop-details__address__value">
                {props.address.additionalLine}
              </div>
            </>
          }
        </div>
      </div>
      <div className="xgs-stop-details__summary">
        <div className="xgs-stop-details__summary__item">
          <div className="xgs-stop-details__summary__item__icon">
            <XGSIcon icon={XGSIcons.faWeightScale} />
          </div>
          <div className="xgs-stop-details__summary__item__text">
            <span>{props.probillsCount}</span>
            <br /> Probill
            {props.probillsCount > 1 ? "s" : ""}
          </div>
        </div>
        <div className="xgs-stop-details__summary__item">
          <div className="xgs-stop-details__summary__item__icon">
            <XGSIcon icon={XGSIcons.faBoxesStacked} />
          </div>
          <div className="xgs-stop-details__summary__item__text">
            <span>{props.itemsCount}</span>
            <br /> Item{props.itemsCount > 1 ? "s" : ""}
          </div>
        </div>
        <div className="xgs-stop-details__summary__item">
          <div className="xgs-stop-details__summary__item__icon">
            <XGSIcon icon={XGSIcons.faPortrait} />
          </div>
          {props.consigneesCount && <div className="xgs-stop-details__summary__item__text">
            <span>{props.consigneesCount}</span>
            <br /> Consignee
            {props.consigneesCount > 1 ? "s" : ""}
          </div>}
        </div>
      </div>
    </>
  )
};
