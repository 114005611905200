import axios, { CancelTokenSource } from "axios";
import api, { ApiResponse } from "../api";
import { PunchModel, WebClockRecordModel } from "../web-clock/models";
import { TerminalLocationModel } from "./models";

export const REQUEST_ID: {
  [key: string]: string;
} = {
  GET_CLOCK_RECORDS: "get_clock_records",
};

class WebClockService {
  private static instance: WebClockService;

  private source: {
    [key: string]: CancelTokenSource;
  };

  private constructor() {
    this.source = {};
  };

  static getInstance(): WebClockService {
    if (!WebClockService.instance) {
      WebClockService.instance = new WebClockService();
    }
    return WebClockService.instance;
  }

  public getLocations = async (): Promise<ApiResponse<TerminalLocationModel[]>> => {
    return await api.get("/paylocity/terminals");    
  };

  public addClockRecords = async (
    records: WebClockRecordModel[],
  ): Promise<any> => {
    return await api.post("/paylocity/punchImport", records);    
  };

  public getClockRecords = async (): Promise<ApiResponse<PunchModel[]>> => {
    this.source[REQUEST_ID.GET_CLOCK_RECORDS] && this.source[REQUEST_ID.GET_CLOCK_RECORDS].cancel();
    this.source[REQUEST_ID.GET_CLOCK_RECORDS] = axios.CancelToken.source();

    return await api.get("/paylocity/punchDetails", { cancelToken: this.source[REQUEST_ID.GET_CLOCK_RECORDS].token });    
  };
}

export default WebClockService;