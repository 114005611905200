import React from "react";
import { Options } from "react-select";
import AsyncSelect from "react-select/async";
import {
  XGSSelectProps,
  XGSSelectOption
} from "./xgsSelect";
import "./xgsSelect.scss";

const maxCountOfOptionsOnSearch = 100;

const XGSAsyncSelect: React.FC<XGSSelectProps> = ({ async, ...props }) => {
  const options = props.options as Options<XGSSelectOption>;

  const loadOptions = (
    inputValue: string
  ) => {
    return Promise.resolve(filterOptions(inputValue));
  };

  const filterOptions = (inputValue: string) => {
    if (options) {
      const filteredOptions = [];
      const optionsCount = options.length;
      const lowerCaseInputValue = inputValue.toLowerCase();
      for (let i = 0; i < optionsCount; i++) {
        const currentOption = options[i];
        const valueForSearch = currentOption.valueForSearch || currentOption.value;
        if (valueForSearch.toString().toLowerCase().includes(lowerCaseInputValue)) {
          filteredOptions.push(currentOption);
        }
        if (filteredOptions.length > maxCountOfOptionsOnSearch) break;
      }
      return filteredOptions;
    }
    return [];
  };

  return (
    <>
      {!async && (
        <AsyncSelect
          {...props}
          cacheOptions
          defaultOptions={options.filter(
            (option: XGSSelectOption, i: number) => (i < maxCountOfOptionsOnSearch)
          )}
          loadOptions={props.loadOptions || loadOptions}
        />
      )}
    </>
  );
};

export default XGSAsyncSelect;
