import { EligibleConsigneeDto } from "../../app/data/notify-consignee/models";

export interface NotifyConsigneeState {
  requestStarted: boolean,
  requestSucceed: boolean,
  requestFailed: boolean,
  requestFailReason?: string | null,
  eligibleConsignees: EligibleConsigneeDto[],
  requestCreator?: string | null
}

export const initialNotifyConsigneeState: NotifyConsigneeState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestFailReason: null,
  eligibleConsignees: [],
  requestCreator: null
};