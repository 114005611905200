import React from "react";
import bellIcon from "../../images/bell.svg";

export const NotifyConsigneeButton: React.FC<{onSelectStop: any}> = (props) => {
  return (
    <div className="xgs-notify-consignee">
      <div
        className="xgs-address-map-button"
        onClick={(e) => {
          e.stopPropagation();
          props.onSelectStop();
        }}
      >
        <div className="xgs-address-map-button__text">
          <img src={bellIcon} alt="notify"/>
        </div>
      </div>
    </div>
  )
}