import axios, { AxiosResponse } from "axios";
import api, { ApiResponse } from "./../api";
import {
  TokenResponseModel,
  TokenRequestModel,
} from "./models";

class UserService {
  private static instance: UserService;
  private constructor() {};
  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  };

  public getAuthToken = async (
    request: TokenRequestModel
  ): Promise<AxiosResponse<TokenResponseModel>> => {
    const params = new URLSearchParams(request).toString();
    axios.interceptors.response.use((response) => response, (error) => error);
    return await axios.post(process.env.REACT_APP_TOKEN_ENDPOINT || "", params, {
      headers: {
        "Content-type": "application/x-www-form-urlencoded"
      }
    });
  };

  public getCurrent = async (): Promise<ApiResponse<any>> => {
    return await api.get("/drivers/current");
  };
}

export default UserService;
