import api, { ApiResponse } from "../api";
import { ArrivalAtDeliveryStopRequest, ArrivalAtPickupStopRequest } from "./models";

class StopDetailsService {
  private static instance: StopDetailsService;
  private constructor() {};

  static getInstance(): StopDetailsService {
    if (!StopDetailsService.instance) {
      StopDetailsService.instance = new StopDetailsService();
    }
    return StopDetailsService.instance;
  };

  public getAllStopsDetails = async (
    manifestNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/drivers/routes/${manifestNumber}/stops`);
  };

  public arriveAtDeliveryStop = async (
    request: ArrivalAtDeliveryStopRequest
  ): Promise<ApiResponse<any>> => {
    return await api.post("/drivers/routes/save/arrival", request);
  };

  public arriveAtPickupStop = async (
    request: ArrivalAtPickupStopRequest
  ): Promise<ApiResponse<any>> => {
    return await api.post("/drivers/routes/pickup/arrival", request);
  };
}

export default StopDetailsService;
