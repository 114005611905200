
export const enum ManifestStatus {
  COMPLETED = "COMPLETED",
  ACTIVE = "ACTIVE",
  PENDING = "PENDING"
};

export const enum StopStatus {
  COMPLETED = "COMPLETED",
  ACTIVE = "ACTIVE",
  UPCOMING = "UPCOMING"
};

export enum TagColor {
  RED = "red",
  YELLOW = "yellow",
  GREEN = "green",  
  GREY = "grey",
  BLUE = "blue",
  PURPLE = "purple",
  ORANGE = "orange",
};
