import { PaylocityRecordTypes } from "../web-clock/models";

export const getRecordLabel = (recordType: PaylocityRecordTypes | null) => {
  switch (recordType) {
    case PaylocityRecordTypes.IN: return "Clock In";
    case PaylocityRecordTypes.OUT: return "Clock Out";
    case PaylocityRecordTypes.START_LUNCH: return "Start Lunch";
    case PaylocityRecordTypes.END_LUNCH: return "End Lunch";
    default: return "";
  }
}