import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import NotifyConsigneeService from "../../app/data/notify-consignee/notifyConsigneeService";
import { initialNotifyConsigneeState } from "./notifyConsigneeState";

const notifyConsigneeService = NotifyConsigneeService.getInstance();

export const notifyConsigneeSlice = createSlice({
  name: "notifyConsignee",
  initialState: initialNotifyConsigneeState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setEligibleConsignees: (state, { payload }) => {
      state.eligibleConsignees = payload;
    },
    resetNotifyConsgineeState: () => initialNotifyConsigneeState
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setEligibleConsignees,
  resetNotifyConsgineeState
} = notifyConsigneeSlice.actions;

export const notifyConsigneeSelector = (state: IState) => state.notifyConsignee;

export const getEligibleConsignees = (
  manifestNumber: number,
  stopNumber: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("ELIGIBLE_CONSIGNEES"));
  const response = await notifyConsigneeService.getEligibleConsignees(manifestNumber, stopNumber);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setEligibleConsignees(response.data));
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
  }
};

export const notifyConsigneesAtStop = (
  manifestNumber: number,
  stopNumber: number,
  notificationType: string,
  onSuccess?: () => void,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("NOTIFY_CONSIGNEE"));
  const response = await notifyConsigneeService.notifyConsigneesAtStop(manifestNumber, stopNumber, notificationType);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess?.()
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
    onFailed && onFailed();
  }
};

export const notifyShippersAtStop = (
  pickupNumbers: string[],
  notificationType: string,
  onSuccess?: () => void,
  onFailed?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("NOTIFY_SHIPPER"));
  const response = await notifyConsigneeService.notifyShippersForPickup(pickupNumbers, notificationType);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess?.()
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
    onFailed && onFailed();
  }
};

const notifyConsigneeReducer = notifyConsigneeSlice.reducer;
export default notifyConsigneeReducer;
