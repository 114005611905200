import api, { ApiResponse } from "./../api";
import { EligibleConsigneeDto } from "./models";


class NotifyConsigneeService {
  private static instance: NotifyConsigneeService;
  private constructor() {};
  static getInstance(): NotifyConsigneeService {
    if (!NotifyConsigneeService.instance) {
      NotifyConsigneeService.instance = new NotifyConsigneeService();
    }
    return NotifyConsigneeService.instance;
  };

  public getEligibleConsignees = async (
    manifestNumber: number,
    stopNumber: number
  ): Promise<ApiResponse<EligibleConsigneeDto[]>> => {
    return await api.get(`/notifications/deliveries/${manifestNumber}/${stopNumber}/eligible`)
  };

  public notifyConsigneesAtStop = async (
    manifestNumber: number,
    stopNumber: number,
    notificationType: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/notifications/deliveries/${manifestNumber}/${stopNumber}/notify`, {
      notificationType
    });
  };

  public notifyShippersForPickup = async (
    pickupNumbers: string[],
    notificationType: string
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/notifications/pickups/notify`, {
      notificationType,
      pickupNumbers
    })};
}

export default NotifyConsigneeService;
