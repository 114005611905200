import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../hooks/storeHooks";
import { useNavigate } from "react-router-dom";
import XGSErrorMessage from "../../components/error-message/errorMessage";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import Loading from "../../components/loading/loading";
import { WebClock } from "../web-clock/webClock";
import { ROUTES } from "../../app/route/RoutesConfig";
import { ManifestStatus } from "../../app/data/common/constants";
import { ManifestModel } from "../../app/data/route-select/models";
import { StopDetails } from "../../app/data/stop-details/models";
import RouteSelectState from "../../slices/route-select/RouteSelectState";
import {
  setSelectedManifest,
  routeSelectSelector
} from "../../slices/route-select/routeSelectSlice";
import NoRoute from "./noRoute";
import PickupsList from "../pickups/list/pickupsList";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import AdditionalDetails from "../additional-details/additionalDetails";
import "./routeSelect.scss";
import "./../../styles/blocks.scss";
import pickupIcon from "./../../images/pickup_gray.svg";

const RouteSelect: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const routeSelectState: RouteSelectState = useSelector(routeSelectSelector);
  const [pickupsAssigned, setPickupsAssigned] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);

  const openRoute = useCallback((manifestNumber: number) => {
    dispatch(setSelectedManifest(manifestNumber));
    navigate(`/${manifestNumber}${ROUTES.route}`);
  }, [dispatch, navigate]);

  const pickupsCount = (manifest: ManifestModel) => {
    return manifest.stops.filter((stop: StopDetails) => stop.type === "PICKUP").length;
  };

  useEffect(() => {
    // open Active manifest when Driver App loaded
    if (!routeSelectState.routeSelectCalledFromMenu && routeSelectState.activeManifest) openRoute(routeSelectState.activeManifest);
  }, [dispatch, openRoute, routeSelectState.activeManifest, routeSelectState.routeSelectCalledFromMenu]);

  return (
    <div className="xgs-route-selector">
      <Loading
        isLoading={routeSelectState.requestStarted}
        className="xgs-route-summary__loading"
      />
      {routeSelectState.requestSucceed && (
        <>
          <WebClock
            onMenuClick={() => { setShowAdditionalDetails(true) }}
          />
          {(routeSelectState.manifests.length > 0) ? (
            <>
              <div className="xgs-route-selector__header">
                You are assigned several routes
              </div>
              <div className="xgs-route-selector__subheader">
                Please clock-in and tap on cards to open routes
              </div>
              <div className="xgs-site__section-header">
                <span>
                  Assigned Routes
                </span>
              </div>
              {routeSelectState.manifests.map((manifest: ManifestModel) => (
                <div
                  className={`xgs-card__block${manifest.status === ManifestStatus.ACTIVE ? " xgs-card__block--w-label" : ""}`}
                  onClick={() => openRoute(manifest.manifestNumber)}
                  key={manifest.manifestNumber}
                >
                  {manifest.status === ManifestStatus.ACTIVE && (
                    <div className="xgs-card__block__label xgs-card__block__label--active">
                      Active
                    </div>
                  )}
                  <div className="xgs-card__line xgs-card__numbers">
                    <div className="xgs-card__numbers__item">
                      <div className="xgs-card__numbers__item__value">{manifest.manifestNumber}</div>
                      Manifest #
                    </div>
                  </div>
                  <div className="xgs-card__line">
                    <div className="xgs-card__numbers__item">
                      Trailer # <span className="xgs-card__numbers__item__value xgs-card__numbers__item__value--inline">{manifest.trailerNumber}</span>
                    </div>
                    <div className="xgs-card__numbers__item">
                      Tractor # <span className="xgs-card__numbers__item__value xgs-card__numbers__item__value--inline">{manifest.tractorNumber}</span>
                    </div>
                  </div>
                  <div className="xgs-card__line xgs-card__totals">
                    <div className="xgs-card__totals__item">
                      <div className="xgs-card__totals__item__icon">
                        <XGSIcon
                          icon={XGSIcons.faMapMarkerAlt}
                        />
                      </div>
                      <div className="xgs-card__totals__item__text">
                        <span>{manifest.stops.length || "-"}</span><br />
                        Stop{manifest.stops.length !== 1 ? "s" : ""}
                      </div>
                    </div>
                    <div className="xgs-card__totals__item">
                      <div className="xgs-card__totals__item__icon">
                        <img src={pickupIcon} alt="Pickups" />
                      </div>
                      <div className="xgs-card__totals__item__text">
                        <span>{pickupsCount(manifest)}</span><br />
                        Pickup{pickupsCount(manifest) !== 1 ? "s" : ""}
                      </div>
                    </div>
                    <div className="xgs-card__totals__item">
                      <div className="xgs-card__totals__item__icon">
                        <XGSIcon
                          icon={XGSIcons.faBoxes}
                        />
                      </div>
                      <div className="xgs-card__totals__item__text">
                        <span>{manifest.numberOfItems || "-"}</span><br />
                        Item{manifest.numberOfItems !== 1 ? "s" : ""}
                      </div>
                    </div>
                    <div className="xgs-card__totals__item">
                      <div className="xgs-card__totals__item__icon">
                        <XGSIcon
                          icon={XGSIcons.faWeight}
                        />
                      </div>
                      <div className="xgs-card__totals__item__text">
                        <span>{parseFloat(manifest.weight.toFixed(2)) || "-"}</span><br />
                        Pound{parseFloat(manifest.weight.toFixed(2)) !== 1 ? "s" : ""}
                      </div>
                    </div>
                  </div>
                  {(manifest.stops.length > 0) && (
                    <div className="xgs-card__line" style={{ gap: "0 64px" }}>
                      <div className="xgs-card__pair">
                        <span>{manifest.stops[0].address.address1}{(manifest.stops[0].address.address1 && manifest.stops[0].address.city) ? ", " : ""}{manifest.stops[0].address.city}</span><br />
                        First stop
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              <NoRoute pickupsAssigned={pickupsAssigned} />
              <PickupsList onPickupsAssigned={(result) => setPickupsAssigned(result)} />
            </>
          )}

          <SlideInModal
            title="Additional Details"
            show={showAdditionalDetails}
            onClose={() => setShowAdditionalDetails(false)}
          >
            <AdditionalDetails key={showAdditionalDetails.toString()} onCancel={() => setShowAdditionalDetails(false)} />
          </SlideInModal>
        </>
      )}      
      
      {routeSelectState.requestFailed && (
        <XGSErrorMessage className="block-center">
          {routeSelectState.requestFailReason || "Error. Please try again later."}
        </XGSErrorMessage>
      )}
    </div>
  );
}

export default RouteSelect;
